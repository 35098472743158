import {
  breakpoints,
  breakpointsNumeric,
  pageGutters,
  space,
  sizes,
} from './layout';
import { borders, shadows, speeds, radii } from './box';
import colors from './colors';
import {
  buildLineHeights,
  fonts, fontSizes,
  fontWeights,
} from './typography';
import buttons from './variants/buttons';
import text from './variants/text';
import link from './variants/link';
import tooltip from './variants/tooltip';
import badge from './variants/badge';

/**
 * Builds the baseTheme as a function of language and other options.
 * @returns {object} the baseTheme theme object
 */
export const buildBaseTheme = () => {
  return {
    // Layout
    breakpoints,
    breakpointsNumeric,
    pageGutters,
    space,
    sizes,

    // Color
    colors,

    // Typography
    fonts: { ...fonts },
    fontSizes: { ...fontSizes },
    lineHeights: buildLineHeights(),
    fontWeights,

    // Box Styles
    borders,
    shadows,
    speeds,
    radii,

    // Variants
    text,
    buttons,
    variants: {
      link,
      tooltip,
      badge,
    },
  };
};

// Default export is a baseTheme built with defaults
export default buildBaseTheme();
