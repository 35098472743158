import { displaySizes } from './variants/text/display';
import { headingSizes } from './variants/text/heading';
import { subheadingSizes } from './variants/text/subheading';
import { bodySizes } from './variants/text/body';

// The primitive definitions of the fonts families
export const fontDefinitions = {
  interAccent: '"Inter-Hewn", sans-serif',
  montserratRegular: '"Montserrat-Regular", sans-serif',
  montserratThin: '"Montserrat-Thin", sans-serif',
  montserratMedium: '"Montserrat-Medium", sans-serif',
  montserratBold: '"Montserrat-Bold", sans-serif',
  montserratItalic: '"Montserrat-Italic", sans-serif',
};

// Our core font tokens. Higher-level & more specific font tokens
// can reference these values so that they reflect any changes here.
export const coreFonts = {
  primary: fontDefinitions.montserratRegular,
  primaryThin: fontDefinitions.montserratThin,
  primaryMedium: fontDefinitions.montserratMedium,
  primaryBold: fontDefinitions.montserratBold,
  primaryItalic: fontDefinitions.montserratItalic,
  secondary: fontDefinitions.interAccent,
};

// Our default list of font tokens. Includes definitions,
// core fonts, and all tokens with a specific font family.
// (Overrides are built using buildFonts())
export const fonts = {
  definitions: fontDefinitions,
  ...coreFonts,
  display: coreFonts.primary,
  heading: coreFonts.primary,
  subheading: coreFonts.secondary,
  body: coreFonts.secondary,
  meta: coreFonts.secondary,
  caption: coreFonts.primary,
  nav: coreFonts.primary,
  menu: coreFonts.primary,
};

// Default fontSizes scale
export const fontSizes = [
  /* 0 */ '12px',
  /* 1 */ '14px',
  /* 2 */ '16px',
  /* 3 */ '20px',
  /* 4 */ '24px',
  /* 5 */ '32px',
  /* 6 */ '40px',
  /* 7 */ '48px',
  /* 8 */ '56px',
  /* 9 */ '70px',
];
// Default lineHeights scale
// (corresponds ordinally to fontSizes scale,
// e.g. use fontSizes[1] lineHeights[1] together)
export const lineHeights = [
  /* 0 */ '16px',
  /* 1 */ '20px',
  /* 2 */ '24px',
  /* 3 */ '28px',
  /* 4 */ '32px',
  /* 5 */ '40px',
  /* 6 */ '52px',
  /* 7 */ '56px',
  /* 8 */ '64px',
  /* 9 */ '88px',
];
// Theme fontWeight tokens
export const fontWeights = {
  thin: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  xbold: 800,
};


/**
 * Builds an ordinal scale of lineHeights and aliases
 * @returns {array} - array of lineHeights, with some aliases as named keys
 * https://styled-system.com/theme-specification#scale-aliases
 */
export function buildLineHeights() {
  const builtLineHeights = [...lineHeights];

  /**
   * lineHeights Aliases:
   * Add the sizes for each of these text variants
   * as useful named properties on lineHeights.
   * Allows you to use a corresponding line-height
   * to any of the fontSize aliases.
   */
  builtLineHeights.tight = '1';
  [
    ['display', displaySizes],
    ['heading', headingSizes],
    ['subheading', subheadingSizes],
    ['body', bodySizes],
  ].forEach(([alias, sizes]) => {
    builtLineHeights[alias] = Object.entries(sizes).reduce(
      (newAlias, [size, value]) => ({
        ...newAlias,
        [size]: builtLineHeights[value],
      }),
      {},
    );
  });

  return builtLineHeights;
}
