// Base display styles
const displayBaseStyles = {
  fontFamily: 'display',
  color: 'text.primary',
};

// The fontSize and lineHeight values for display presets
export const displaySizes = {
  xxs: 1,
  xs: 2,
  sm: 3,
  md: 5,
  lg: 7,
  xl: 9,
};

// The space (intended for margin-bottom) values for
// display presets in a longform copy setting (ie, reports)
// export const displaySpacings = {
//   sm: 2,
//   md: 3,
//   lg: 4,
//   xl: 5,
// };

export default {
  ...displayBaseStyles,

  // display size presets
  xxs: {
    ...displayBaseStyles,
    fontSize: displaySizes.xxs,
    lineHeight: displaySizes.xxs,
  },
  xs: {
    ...displayBaseStyles,
    fontSize: displaySizes.xs,
    lineHeight: displaySizes.xs,
  },
  sm: {
    ...displayBaseStyles,
    fontSize: displaySizes.sm,
    lineHeight: displaySizes.sm,
  },
  md: {
    ...displayBaseStyles,
    fontSize: displaySizes.md,
    lineHeight: displaySizes.md,
  },
  lg: {
    ...displayBaseStyles,
    fontSize: displaySizes.lg,
    lineHeight: displaySizes.lg,
  },
  xl: {
    ...displayBaseStyles,
    fontSize: displaySizes.xl,
    lineHeight: displaySizes.xl,
  },
};
