// Base heading styles
const headingBaseStyles = {
  fontFamily: 'secondary',
  textTransform: 'uppercase',
  color: 'text.primary',
  fontWeight: 'bold',
};

// The fontSize and lineHeight values for heading presets
export const headingSizes = {
  xxs: 0,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 6,
  xxl: 7,
};

// The space (intended for margin-bottom) values for
// heading presets in a longform copy setting (ie, reports)
// export const headingSpacings = {
//   xs: 1,
//   sm: 2,
//   md: 3,
//   lg: 4,
//   xl: 5,
//   xxl: 7,
// };

export default {
  ...headingBaseStyles,

  // heading size presets
  xxs: {
    ...headingBaseStyles,
    fontSize: headingSizes.xxs,
    lineHeight: headingSizes.xxs,
  },
  xs: {
    ...headingBaseStyles,
    fontSize: headingSizes.xs,
    lineHeight: headingSizes.xs,
  },
  sm: {
    ...headingBaseStyles,
    fontSize: headingSizes.sm,
    lineHeight: headingSizes.sm,
  },
  md: {
    ...headingBaseStyles,
    fontSize: headingSizes.md,
    lineHeight: headingSizes.md,
  },
  lg: {
    ...headingBaseStyles,
    fontSize: headingSizes.lg,
    lineHeight: headingSizes.lg,
  },
  xl: {
    ...headingBaseStyles,
    fontSize: headingSizes.xl,
    lineHeight: headingSizes.xl,
  },
  xxl: {
    ...headingBaseStyles,
    fontSize: headingSizes.xxl,
    lineHeight: headingSizes.xxl,
  },
};
