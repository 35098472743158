// Base subheading styles
const subheadingBaseStyles = {
  fontFamily: 'subheading',
  color: 'text.primary',
};

// The fontSize and lineHeight values for subheading presets
export const subheadingSizes = {
  sm: 1,
  md: 2,
};

// The space (intended for margin-bottom) values for
// subheading presets in a longform copy setting (ie, reports)
// export const subheadingSpacings = {
//   sm: 2,
//   md: 3,
// };

export default {
  ...subheadingBaseStyles,

  // subheading size presets
  sm: {
    ...subheadingBaseStyles,
    fontSize: subheadingSizes.sm,
    lineHeight: subheadingSizes.sm,
  },
  md: {
    ...subheadingBaseStyles,
    fontSize: subheadingSizes.md,
    lineHeight: subheadingSizes.md,
    fontWeight: 500,
  },
};
