import display from './display';
import heading from './heading';
import subheading from './subheading';
import body from './body';
import meta from './meta';
import caption from './caption';
import nav from './nav';
import menu from './menu';

export default {
  display,
  heading,
  subheading,
  body,
  meta,
  caption,
  nav,
  menu,
};
