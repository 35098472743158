// Common styles for body
const baseBodyStyles = {
  fontFamily: 'primary',
  color: 'text.body',
};

// The fontSize and lineHeight values for body presets
export const bodySizes = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
};

export default {
  ...baseBodyStyles,
  xs: {
    ...baseBodyStyles,
    fontSize: bodySizes.xs,
    lineHeight: bodySizes.xs,
  },
  sm: {
    ...baseBodyStyles,
    fontSize: bodySizes.sm,
    lineHeight: bodySizes.sm,
  },
  md: {
    ...baseBodyStyles,
    fontSize: bodySizes.md,
    lineHeight: bodySizes.md,
  },
  lg: {
    ...baseBodyStyles,
    fontSize: bodySizes.lg,
    lineHeight: bodySizes.lg,
  },
};
