/**
 * Standard buttons
 */

// Shared styles
const commonButtonStyles = {
  display: 'inline-flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  py: 2,
  px: 3,
  minWidth: '164px',
  borderRadius: '0',
  fontFamily: 'primary',
  fontSize: 1,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  outline: 'none',
  '& > *:not(:last-child)': {
    mr: 1,
  },
  '@media': {},
};

const commonDisabledStyles = {
  cursor: 'default',
  pointerEvents: 'none',
};

// Variants
const buttonVariants = {
  contained: {
    ...commonButtonStyles,
    bg: 'inverse.background.secondary',
    borderColor: 'inverse.background.secondary',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: 'inverse.text.primary',
    svg: { fill: 'inverse.text.primary' },
    '&:hover:not([disabled]):not(.disabled), &:focus:not([disabled]):not(.disabled), &:active:not([disabled]):not(.disabled)': {
      bg: 'inverse.background.primary',
      borderColor: 'inverse.background.primary',
    },
    '&:disabled, &.disabled': {
      ...commonDisabledStyles,
      bg: 'background.tertiary',
      borderColor: 'background.tertiary',
      color: 'text.secondary',
      svg: { fill: 'text.secondary' },
    },
  },
  outlined: {
    ...commonButtonStyles,
    background: 'none',
    color: 'text.primary',
    svg: { fill: 'text.primary' },
    borderColor: '#000',
    borderWidth: '2px',
    borderStyle: 'solid',
    '&:hover:not([disabled]):not(.disabled)': {
      borderColor: '#000',
      color: '#fff',
      background: '#000'
    },
    // '&:active:not([disabled]):not(.disabled), &:focus:not([disabled]):not(.disabled)': {
    //   background: '#fff',
    //   color: '#000'
    // },
    '&:disabled, &.disabled': {
      ...commonDisabledStyles,
      color: 'text.tertiary',
      svg: { fill: 'text.tertiary' },
      borderColor: 'border.primary',
    },
  },
  borderless: {
    ...commonButtonStyles,
    background: 'none',
    borderColor: 'transparent',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: 'text.primary',
    svg: { fill: 'text.primary' },
    '&:disabled, &.disabled': {
      ...commonDisabledStyles,
      color: 'text.tertiary',
      svg: { fill: 'text.tertiary' },
    },
  },
  danger: {
    ...commonButtonStyles,
    background: 'none',
    color: 'text.danger',
    svg: { fill: 'text.danger' },
    borderColor: 'border.danger',
    borderWidth: '1px',
    borderStyle: 'solid',
    '&:hover:not([disabled]):not(.disabled), &:focus:not([disabled]):not(.disabled), &:active:not([disabled]):not(.disabled)': {
      borderColor: 'border.highlight',
    },
    '&:disabled, &.disabled': {
      ...commonDisabledStyles,
      color: 'text.tertiary',
      svg: { fill: 'text.tertiary' },
      borderColor: 'border.primary',
    },
  },
};

/**
 * Round icon-only buttons (action buttons)
 */

// Shared styles
const commonActionButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: 0,
  borderRadius: '50%',
  cursor: 'pointer',
  outline: 'none',
  bg: 'background.primary',
  color: 'text.primary',
  svg: {
    fill: 'inverse.background.secondary',
    size: 'icon.md',
    opacity: '.7',
  },
  '&:hover, &:focus, &:active': {
    svg: { opacity: '1' },
  },
  '&.shadow': {
    boxShadow: 'normal',
  },
};

// Variants
const actionButtonVariants = {
  action: {
    ...commonActionButtonStyles,
    sm: {
      ...commonActionButtonStyles,
      size: 'actionButton.sm',
      svg: {
        ...commonActionButtonStyles.svg,
        size: 'icon.sm',
      },
    },
    md: {
      ...commonActionButtonStyles,
      size: 'actionButton.md',
      svg: {
        ...commonActionButtonStyles.svg,
        size: 'icon.md',
      },
    },
    lg: {
      ...commonActionButtonStyles,
      size: 'actionButton.lg',
      svg: {
        ...commonActionButtonStyles.svg,
        size: 'icon.lg',
      },
    },
  },
};

export default {
  ...buttonVariants,
  ...actionButtonVariants,
};
