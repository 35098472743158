import React, {useMemo} from 'react';
import {ThemeProvider} from 'styled-components';
import {buildBaseTheme} from "../../theme";

const AppThemeProvider = props => {
  const {children} = props;

  // Build the lang-specific base theme
  const baseTheme = useMemo(() => buildBaseTheme(), []);

  return (
    <ThemeProvider theme={baseTheme}>
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
