import { transparentize } from 'polished';

// The primitive definitions of our color values
// (Keeping these grouped helps with documentation in storybook)
export const basicColors = {
  black: '#000000',
  black80: transparentize(0.2, '#000000'),
  black60: transparentize(0.4, '#000000'),
  black40: transparentize(0.6, '#000000'),
  black20: transparentize(0.8, '#000000'),
  white: '#ffffff',
  whiteGrayed: '#F6F7F9',
  white80: transparentize(0.2, '#ffffff'),
  white60: transparentize(0.4, '#ffffff'),
  white40: transparentize(0.6, '#ffffff'),
  white20: transparentize(0.8, '#ffffff'),
  red: '#E53A32',
  yellow: '#EFC825',
  blue: '#292C61',
};
export const greys = {
  xdkGrey: '#333333',
  dkGrey: '#666666',
  mdGrey: '#999999',
  ltGrey: '#cccccc',
  xltGrey: '#f2f2f2',
};
export const accentColors = {
  pine: '#508A4D',
  ltPine: '#F2F8F2',
  denim: '#4868B4',
  ltDenim: '#ECF1FF',
  teal: '#308D9B',
  ltTeal: '#EBF7F7',
  lead: '#6F7E8B',
  ltLead: '#EEF2F6',
  sand: '#A68451',
  ltSand: '#F6F2EB',
  rust: '#CD6750',
  ltRust: '#F9EFEB',
  berry: '#B14D58',
  ltBerry: '#F6E9EB',
  plum: '#8B5995',
  ltPlum: '#F6ECF8',
};

// The full list of all color definitions
export const definitions = {
  ...basicColors,
  ...greys,
  ...accentColors,
};

const core = {
  primary: definitions.black,
  secondary: definitions.white,
};

// The theme's full colors object
const colors = {
  definitions,
  test: 'magenta',

  // Our main color tokens for use in variants and around the application.
  ...core,
  border: {
    primary: definitions.black20,
    secondary: definitions.black40,
    highlight: core.primary,
    danger: definitions.red,
  },
  text: {
    black: definitions.black,
    primary: definitions.white80,
    yellow: definitions.yellow,
    secondary: definitions.black60,
    tertiary: definitions.black40,
    body: definitions.black80,
    danger: definitions.red,
  },
  background: {
    primary: definitions.whiteGrayed,
    white: definitions.white,
    secondary: definitions.xltGrey,
    tertiary: definitions.ltGrey,
    blue: definitions.blue
  },
  accent: {
    dark: definitions.lead,
    light: definitions.ltLead,
  },
  overlay: definitions.black80,

  /**
   * Inverse theme colors
   * Keys here will be used by invertTheme() to swap with their
   * matching key one level above.
   * E.g. colors.primary <---> colors.inverse.primary
   */
  inverse: {
    primary: core.secondary,
    secondary: core.primary,
    border: {
      primary: definitions.white20,
      secondary: definitions.white40,
      highlight: core.secondary,
    },
    text: {
      primary: definitions.white,
      secondary: definitions.white60,
      tertiary: definitions.white40,
      body: definitions.white80,
    },
    background: {
      primary: definitions.black,
      secondary: definitions.xdkGrey,
      tertiary: definitions.dkGrey,
    },
  },
};

export default colors;
