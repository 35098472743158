const linkBaseStyles = {
  color: 'inherit',
  display: 'inline',
  cursor: 'pointer',
  textDecoration: 'none',
  backgroundSize: '200% 1px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100% bottom',
  transition: 'background-position 300ms ease',
  '&:hover, .link-wrapper:hover &': {
    backgroundPosition: '0% bottom',
  },
};
const link = {
  visible: {
    ...linkBaseStyles,
    backgroundImage: ({ colors }) =>
      `linear-gradient(to right, ${colors.text.primary} 50%, ${colors.text.tertiary} 0%)`,
  },
  hidden: {
    ...linkBaseStyles,
    backgroundImage: ({ colors }) =>
      `linear-gradient(to right, ${colors.text.primary} 50%, transparent 0%)`,
  },
};

export default link;
