import {createGlobalStyle} from 'styled-components';

// Creates a GlobalStyles component
// to inject global styles into the <head> of the page
const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter-Hewn';
    font-display: swap;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: url('/fonts/Inter-hewn.otf') format('truetype');
  }


  @font-face {
    font-family: 'Montserrat-Thin';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Montserrat-Thin.otf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat-Italic';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Montserrat-Italic.otf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat-Regular';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Montserrat-Regular.otf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat-Medium';
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/Montserrat-Medium.otf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat-Bold';
    font-display: swap;
    font-weight: 700;
    src: url('/fonts/Montserrat-Bold.otf') format('truetype');
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    overflow-x: hidden;
  }

  body.disable-scroll {
    overflow: hidden;
    height: 100%;
  }

  #modal-root {
    position: relative;
    z-index: 200000;
  }

  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 20000;
  }

  .ReactModal__Content {
    width: 500px;
    height: 350px;
    top: calc(50% - 125px) !important;
    margin: 0 auto;
  }
  
  .Collapsible {
    border: 2px solid #000;
    padding: 20px;
    border-radius: 12px;
  }

  // Insert any app-specific global styles here!
`;

export default GlobalStyles;
