import {ModalProvider} from "react-modal-hook";
import AppThemeProvider from "../src/hooks/AppThemeProvider";
import NextHead from 'next/head';
import {Box} from "rebass/styled-components";
import useWindowSize from "../src/hooks/useWindowSize";
import {appWithTranslation} from 'next-i18next';
import styled from 'styled-components';
import '../styles/globals.css'
import '../src/components/AppHeader/components/Menu.Styles.css';
import '../src/components/AppHeader/components/ToogleSwitch.styles.css';
import 'swiper/swiper.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tiny-fab/dist/styles.css';
import GlobalStyles from "../src/hooks/AppThemeProvider/AppThemeProvider.styles";
import {useRouter} from "next/router";
import React, {useEffect, useRef, useState} from "react";
import loadable from '@loadable/component';
import Script from 'next/script';

const AppFooter = loadable(() => import('../src/components/AppFooter'));
const AppHeader = loadable(() => import('../src/components/AppHeader'));

function MyApp({Component, pageProps}) {

  const [width] = useWindowSize();
  const router = useRouter();
  const footerRef = useRef();
  //
  // const [loading, setLoading] = useState(true);
  //
  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    const {utm_source, utm_medium, utm_campaign, utm_content} = router.query;
    if (!window.sessionStorage.getItem('utm_marks')) {
      window.sessionStorage.setItem('utm_marks', JSON.stringify({
        utm_campaign,
        utm_content,
        utm_source,
        utm_medium
      }));
      // router.push(router.pathname, undefined, {shallow: true})
    }
  }, [router])


  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    window.fbq('track', 'PageView');

    const handleRouteChange = () => {
      window.fbq('track', 'PageView');
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    };
  }, [router]);

  return (
    <>
      <NextHead>
        <title>Carfind</title>
      </NextHead>

      <AppThemeProvider>
        <GlobalStyles/>
        <ModalProvider>
          <AppWrapper id='app-wrapper'>
            <div id="modal-root"/>
            <AppHeader screenWidth={width} footerAnchorRef={footerRef}/>
            <script src={`https://www.googletagmanager.com/gtm.js?id=GTM-KNDHQ7Q`} async />
            {/*<Script*/}
            {/*  id="fb-pixel"*/}
            {/*  strategy="afterInteractive"*/}
            {/*  dangerouslySetInnerHTML={{*/}
            {/*      __html: `*/}
            {/*!function(f,b,e,v,n,t,s)*/}
            {/*{if(f.fbq)return;n=f.fbq=function(){n.callMethod?*/}
            {/*n.callMethod.apply(n,arguments):n.queue.push(arguments)};*/}
            {/*if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';*/}
            {/*n.queue=[];t=b.createElement(e);t.async=!0;*/}
            {/*t.src=v;s=b.getElementsByTagName(e)[0];*/}
            {/*s.parentNode.insertBefore(t,s)}(window, document,'script',*/}
            {/*'https://connect.facebook.net/en_US/fbevents.js');*/}
            {/*fbq('init', '253686276880558');*/}
            {/* ` ,*/}
            {/*  }}*/}
            {/*/>*/}
            <Component {...pageProps} screenWidth={width}/>
            <AppFooter anchorRef={footerRef} screenWidth={width}/>
          </AppWrapper>
        </ModalProvider>
      </AppThemeProvider>
    </>
  )
}

const AppWrapper = styled(Box)`
  background: ${({theme}) => theme.colors.background.primary};
`

export default appWithTranslation(MyApp)
