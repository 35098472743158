const tooltipStyles = {
  py: 1,
  px: 1,
  textAlign: 'center',
  bg: 'inverse.background.secondary',
  color: 'inverse.text.primary',
  borderRadius: 'sm',
  fontFamily: 'caption',
  fontSize: '0',
  zIndex: 1,
};

export default tooltipStyles;
