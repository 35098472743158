// Box styles for container elements

export const borders = {
  // TODO: figure out how to themify this color
  // or avoid shorthand (use borderColor, borderWidth, borderStyle instead)
  normal: `1px solid #ccc`,
};
export const shadows = { normal: '0px 4px 4px rgba(0, 0, 0, 0.1)' };
export const radii = { sm: '2px', md: '4px', lg: '8px', circle: '50%' };
export const speeds = {
  xSlow: '300ms',
  slow: '250ms',
  normal: '200ms',
  fast: '150ms',
  xFast: '100ms',
};
