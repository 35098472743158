// Layout
export const breakpoints = ['600px', '900px', '1200px', '1600px'];
export const breakpointsNumeric = [600, 900, 1200, 1600];

export const space = [
  /* 0 */ 0,
  /* 1 */ 8,
  /* 2 */ 12,
  /* 3 */ 18,
  /* 4 */ 24,
  /* 5 */ 32,
  /* 6 */ 40,
  /* 7 */ 48,
  /* 8 */ 64,
  /* 9 */ 80,
  /* 10 */ 100,
];

export const pageGutters = [
  `${space[3]}px`,
  `${space[5]}px`,
  `${space[5]}px`,
  '4%',
  '12%',
];

// Sizes
// For things like widths/minWidths/maxWidths
// styled-system.com/theme-specification#key-reference
export const sizes = {
  // https://ishadeed.com/article/styling-wrappers-css/
  pageWrapper: {
    xs: '400px',
    sm: breakpoints[0],
    md: breakpoints[1],
    lg: breakpoints[2],
    xl: breakpoints[3],
  },
  avatar: {
    md: `32px`,
  },
  icon: {
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '20px',
    xl: '24px',
    xxl: '32px',
  },
  actionButton: {
    sm: '32px',
    md: '48px',
    lg: '60px',
  },
};

// zIndices?
// named? export const zIndices =  {drawer, modal, alert, tooltip, etc}
// ordinal? export const zIndices = [0, 100, 200, 300, 400, ...etc]?
